.certificatesModalWrap {
  height:800px;
  max-height:90vh;
  width:1200px;
  padding: 40px 20px;
  overflow-y: auto;
  display:flex;
  flex-direction: column;

}
.tableWrapper {
  max-height: 600px;
  overflow-y: auto;
}
.addModalWrap {
  width: 600px;
  padding: 20px;
}
.iframeModal {
  padding:20px;
  width: 800px;
  height: 800px;
}
.smallButton {
  // padding: 0.25rem 0.5rem; 
  padding-left:0.85rem ;// Adjust padding for smaller size
  padding-right:0.85rem ;// Adjust padding for smaller size
  font-size: 0.775rem; // Smaller font size
  margin-left: 0.85rem;
}
.linkButton{
  font-size: 0.875rem;
}