.wrapper {
    width:70vw;
    height:60vh;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}
.expandedRowWrap {

}
.hideRow {
    transition: all 0.4s ease-in-out;
    height: 0;
    max-height: 0px;
    overflow: hidden;
}
.tableWrapper {
    height: 600px;
    overflow-y: auto;

}
.showRow {
    transition: all 0.4s ease-in-out;
    height:100%;
    max-height: 2000px;
    overflow: hidden;
}
.found {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.manufacture{
    max-width: 70px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.validity{
    max-width: 30px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.model{
    max-width: 30px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.referenceNumber{
    width: 160px;
    max-width: 200px;
    overflow: hidden;
    text-align: left;
    @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.source{
    max-width: 9px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.linkButton{
  
    text-align: center;
    // padding-left: 100px;
   
}
.cdocid{
    width: 100px;
    // max-width: 100px;
    overflow: hidden;
    // background: red;
    text-overflow: ellipsis;
    @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.certificateNumber{
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  @apply whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500
}
.rowDataWrap {
    padding:24px;
    max-width: 600px;
    overflow-y: auto;

    height: 40vh;
}
.modelView{
    // display: -webkit-box;
    // -webkit-line-clamp:4;
    // -webkit-box-orient: vertical;
    // text-overflow: ellipsis;

}

.commentView{
    width: 50vw;
}